<template>
  <div class="channelOne">
    <div class="back" @click="goBack">
      <i class="el-icon-arrow-left"></i> 返回
    </div>
    <div class="search">
      <el-input v-model="queryParams.name" placeholder="请输入姓名">
        <div class="searchBtn" slot="prefix" @click="toSearch">搜索姓名</div>
      </el-input>
      <img
        src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/shaixuan.png"
        alt=""
        @click="toSelect"
      />
    </div>

    <!-- 筛选板 -->
    <div class="selectBox" v-if="isSelect">
      <div class="selectMain">
        <div class="title">返佣状态</div>
        <div class="fyBox">
          <!-- fy fyActive -->
          <div
            :class="queryParams.fyState == 1 ? 'fy fyActive' : 'fy'"
            @click="fanYong"
          >
            已返佣
          </div>
          <div
            :class="queryParams.fyState == null ? 'fy fyActive' : 'fy'"
            @click="noFanyong"
          >
            未返佣
          </div>
        </div>
        <div class="title">创建日期</div>
        <el-date-picker
          v-model="begin"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="开始时间"
        >
        </el-date-picker>

        <el-date-picker
          v-model="end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="结束时间"
        >
        </el-date-picker>

        <div class="anniu">
          <div class="chongzhi" @click="toEmpty">重置</div>
          <div class="wancheng" @click="toComplate">完成</div>
        </div>
      </div>
    </div>

    <div class="fan">
      当前待返总金额: <span>{{ allPrice }}</span
      >元
    </div>
    <!-- 用户数据 -->
    <el-table :data="tableData" border style="width: 100%; margin-top: 20px">
      <el-table-column prop="createTime" label="创建时间" width="100px">
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="74px"> </el-table-column>
      <el-table-column prop="price" label="返佣金额" width="75px">
      </el-table-column>
      <el-table-column prop="dealTime" label="成交时间" width="100px">
      </el-table-column>
      <el-table-column prop="fyState" label="返佣状态" width="100px">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.fyState == '已返佣'
                ? 'point pointGreen'
                : 'point pointRed'
            "
          ></span>
          <span
            :class="scope.row.fyState == '已返佣' ? 'greenFont' : 'redFont'"
          >
            {{ scope.row.fyState }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import { list } from "@/api/kindergarten/h5qiantai";
export default {
  name: "channelFour",
  components: {},
  data() {
    return {
      isSelect: false,
      begin: "",
      end: "",
      tableData: [],
      allPrice: 0,
      queryParams: {
        name: "",
        fyState: null,
        params: {},
        userName: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toEmpty() {
      this.queryParams.fyState = null;
      this.begin = "";
      this.end = "";
      this.queryParams.params["reservationTimeBig"] = this.end;
      this.queryParams.params["reservationTimeEnd"] = this.begin;

      this.queryParams.userName = localStorage.getItem("h5userName");
      this.api.list(this.addDateRange(this.queryParams)).then((res) => {
        this.tableData = res.data.data[0];
        this.isSelect = false;
      });
    },

    toComplate() {
      if (this.end) {
        this.queryParams.params["reservationTimeBig"] = this.end;
      }
      if (this.begin) {
        this.queryParams.params["reservationTimeEnd"] = this.begin;
      }

      this.queryParams.userName = localStorage.getItem("h5userName");
      this.api.list(this.addDateRange(this.queryParams)).then((res) => {
        this.tableData = res.data.data[0];
        this.isSelect = false;
      });
    },
    toSearch() {
      if (this.end) {
        this.queryParams.params["reservationTimeBig"] = this.end;
      }
      if (this.begin) {
        this.queryParams.params["reservationTimeEnd"] = this.begin;
      }

      this.queryParams.userName = localStorage.getItem("h5userName");
      this.api.list(this.addDateRange(this.queryParams)).then((res) => {
        this.tableData = res.data.data[0];
      });
    },
    toSelect() {
      this.isSelect = true;
    },
    fanYong() {
      this.queryParams.fyState = 1;
    },
    noFanyong() {
      this.queryParams.fyState = null;
    },
    getList() {
      let userName = localStorage.getItem("h5userName");
      this.api.list({ userName }).then((res) => {
        console.log(res.data);
        this.tableData = res.data.data[0];
        this.allPrice = res.data.data[1][0].allPrice;
      });
    },
  },
};
</script>
 <style>
.channelOne {
  width: 100vw;
  height: 100vh;
  background-image: url("https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/bg.png");
  background-size: 100% 100%;
}

.back {
  width: 80px;
  height: 60px;
  font-size: 15px;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.search {
  width: 375px;
  margin: 0 auto;
  padding-top: 12px;
  display: flex;
  justify-content: space-around;
}
.el-input--medium,
.el-input__inner,
.el-input {
  width: 300px;
  height: 50px !important;
  background: #ffffff;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  display: block;
  position: relative;
}
img {
  width: 32px;
  height: 32px;
  margin-top: 5px;
}
.searchBtn {
  width: 72px;
  height: 50px;
  background: #03549e;
  border-radius: 0px 30px 30px 0px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 228px;
  z-index: 99;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.selectBox {
  width: 100%;
  background: #ffffff;
  padding-bottom: 20px;
  position: fixed;
  z-index: 9;
  top: 130px;
}
.selectMain {
  width: 343px;
  margin: 0 auto;
}
.title {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  margin-bottom: 12px;
  margin-top: 10px;
}
.fyBox {
  width: 225px;
  height: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.fy {
  width: 106px;
  height: 40px;
  background: #f3f3f3;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  line-height: 40px;
}
.fyActive {
  width: 106px;
  height: 40px;
  background: #f2f3ff;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  border: 1px solid #0052d9;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #0052d9;
  text-align: center;
  line-height: 40px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 150px;
}

.anniu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.chongzhi {
  width: 167px;
  height: 48px;
  background: #f2f3ff;
  border-radius: 6px 6px 6px 6px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #0052d9;
  text-align: center;
  line-height: 48px;
}
.wancheng {
  width: 167px;
  height: 48px;
  background: #0052d9;
  border-radius: 6px 6px 6px 6px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
}
.el-table .cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.redFont {
  color: #d54941;
}
.greenFont {
  color: #2ba471;
}
.fan {
  color: white;
  margin: 20px;
  span {
    text-decoration: underline;
  }
}

.point {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
}
.pointRed {
  background: #d54941;
}
.pointGreen {
  background: #2ba471;
}
</style> 
